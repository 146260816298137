import './App.css';
import React, { useState } from 'react';

async function App() {

    var password =''


  const [inputValue, setInputValue] = useState('');

  // function that updates the state with the input value
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  }

  function downloadFile(filename, content) {
    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleInputSubmit = async (inputValue) => {
    if (password === 'pcCqVfszGmkBcGGNkQn7vLUM6rG2PfMfLzhYLVzRsTbzajXCTKmxk7Rx8') {
        console.log(`Input value: ${inputValue}`);
        
        const url = inputValue;
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'file.txt';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            downloadFile('file.txt', document.body);
          })
          .catch(error => {
            console.error('Error:', error);
          });

        


    } else {
      alert('Incorrect password!');
    }
  }

  return (
    <div>
      <label>Enter a value:</label>
      <input type="url" value={inputValue} onChange={handleInputChange} />
      <input type="password" value={password} onChange={handleInputChange} />
      <button onClick={() => handleInputSubmit(inputValue)}>Submit your url</button>
    </div>
  );
  
}

export default App;
